import React from 'react'

import blogStyles from './blog.module.scss'
import Layout from '../components/layout'
import SEO from '../components/seo'

const BlogPage = () => {
  return (
    <Layout>
      <SEO title="Blog"/>
      <div className={blogStyles.mainContent}>
        <h1 className={blogStyles.title}>Blog page</h1>
        <p>Posts serão mostrados aqui mais tarde.</p>
      </div>
    </Layout>
  )
}

export default BlogPage